import React, { useEffect } from "react";

//style
import "../../style/App.css";
import "../../style/Menu.css";
import "../../style/media/Menu-media.css";

import { useDispatch, useSelector } from "react-redux";
import { GetProducts } from "../../redux/slice/Product-Slice";
// import Menu_Class from "./Menu_Claas";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import MenuList from "./MenuList";
import Menu_Class from "./Menu_Claas";

const UrlApi = "https://menu.hamdyshalaby.com/";

const Menu = () => {
  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetProducts());
  }, [dispatch]);

  return (
    <>
      <div className="menu">
        <div className="container">
          <div className="content">
            <p className="hed">اشهر الاطباق</p>

            <h1>اشهر اطباق حمدي شلبي</h1>

            <div className="row boxf-cont">
              {Array.isArray(products) && products.length > 0 ? (
                <Swiper
                  spaceBetween={20}
                  slidesPerView={4}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    425: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {products.map((item) => (
                    <SwiperSlide key={item.id} className="boxf-cont">
                      <div className="boxf mx-auto">
                        <div className="imge">
                          <img src={`${UrlApi}${item.image}`} alt={item.name} />
                        </div>
                        <div className="conteen">
                          <div className="heading">
                            <h3>{item.name}</h3>
                            <p>
                              {item.coupon_price != null ? (
                                <>
                                  <span>سعر المنتج : </span>
                                  <s>{Math.round(item.price)} ج</s>
                                  <span> {Math.round(item.final_price)} ج</span>
                                </>
                              ) : (
                                <p>سعر المنتج : {Math.round(item.price)} ج</p>
                              )}
                            </p>
                          </div>
                          <div className="middle"></div>
                          <div className="descr">
                            <p>{item.desc}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <h2> لا يوجد منتجات لعرضها </h2>
              )}
            </div>
          </div>
        </div>
      </div>

      <Menu_Class />
    </>
  );
};

export default Menu;
