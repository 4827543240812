import { createBrowserRouter, RouterProvider } from "react-router-dom";




//component
import Root from "./router/Root";
import Header from "../src/router/Pages/Header";
import Menu from "./router/Pages/Menu";
import Offer from "./router/Pages/Offer";
import About from "./router/Pages/About";


//style
import "./style/App.css";
import HelloPgae from "./router/Pages/HelloPgae";
import InsertMenu1 from "./router/Pages/resturantMenu/InsertMenu1";
import InsertMenu2 from "./router/Pages/resturantMenu/InsertMenu2";
import InsertMenu3 from "./router/Pages/resturantMenu/InsertMenu3";
import InsertMenu4 from "./router/Pages/resturantMenu/InsertMenu4";
import InsertMenu5 from "./router/Pages/resturantMenu/InsertMenu5";
import InsertMenu6 from "./router/Pages/resturantMenu/InsertMenu6";
import InsertMenu7 from "./router/Pages/resturantMenu/InsertMenu7";
import InsertMenu8 from "./router/Pages/resturantMenu/InsertMenu8";
import InsertMenu9 from "./router/Pages/resturantMenu/InsertMenu9";
import InsertMenu10 from "./router/Pages/resturantMenu/InsertMenu10";
import InsertMenu11 from "./router/Pages/resturantMenu/InsertMenu11";
import InsertMenu12 from "./router/Pages/resturantMenu/InsertMenu12";
import Favoritepage from "./component/Favoritepage";
import InsertMenu13 from "./router/Pages/resturantMenu/InsertMenu13";
import InsertMenu14 from "./router/Pages/resturantMenu/InsertMenu14";



const routers = createBrowserRouter([{
  path: '/',
  element: <HelloPgae />
},
{
  path: '/home',
  element: <Root />,

  children: [{
    index: true,
    element: <Header />
  },
  {
    path: 'menu',
    element: <Menu />
  },
  {
    path: 'offer',
    element: <Offer />
  },
  {
    path: 'aboutme',
    element: <About />
  },
  {
    path: 'favorite',
    element: <Favoritepage />
  },
  {
    path: "menu1",
    element: <InsertMenu1 />
  },
  {
    path: "menu2",
    element: <InsertMenu2 />
  },
  {
    path: "menu3",
    element: <InsertMenu3 />
  },
  {
    path: "menu4",
    element: <InsertMenu4 />
  },
  {
    path: "menu5",
    element: <InsertMenu5 />
  },
  {
    path: "menu6",
    element: <InsertMenu6 />
  }
  ,
  {
    path: "menu7",
    element: <InsertMenu7 />
  },
  {
    path: "menu8",
    element: <InsertMenu8 />
  },
  {
    path: "menu9",
    element: <InsertMenu9 />
  },
  {
    path: "menu10",
    element: <InsertMenu10 />
  },
  {
    path: "menu11",
    element: <InsertMenu11 />
  },
  {
    path: "menu12",
    element: <InsertMenu12 />
  },
  {
    path: "menu13",
    element: <InsertMenu13 />
  },
  {
    path: "menu14",
    element: <InsertMenu14 />
  }
  ]
},
])


function App() {
  return (<RouterProvider router={routers} />);
}

export default App;
