import React from 'react'
import { Link } from "react-router-dom";

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import specific icons from the free-solid-svg-icons package
import {
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

// Importing styles
import '../../style/App.css';
import '../../style/MapChoose.css';
import '../../style/media/MapChoose-media.css';



const MapChoose = () => {
  return (
    <>
      <div className="MapCh">
        <div className="container">
          <div className="hed">
            <h1>
              فروعنا 
            </h1>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6 mx-auto">
              <div className="mapee">
                <div className="mape">
                  <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3418.5724946858536!2d31.39728862475701!3d31.038158374438353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f79dbe4aaa8555%3A0x5ad28a8c2afee581!2zSGFtZHkgU2hhbGFieSBSZXN0YXVyYW50IHwg2YXYt9i52YUg2K3Zhdiv2Yog2LTZhNio2Yog2YTZhNmF2LTZiNmK2KfYqg!5e0!3m2!1sar!2seg!4v1724443090969!5m2!1sar!2seg"
                  width="85%"
                  height="450px"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                  className='mapp'
                ></iframe>
              </div>

              <ul className='locmap'>
                <li className='icon-card'>
                  <a href='https://maps.app.goo.gl/FR1pz2ckPDhYEHG16' target='blank'>
                    <div className="icon-side">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>

                    <p className="txt-side">
                      المنصورة شارع الجيش شيل اوت 
                    </p>
                  </a>
                </li>

                <li className='icon-card'>
                  <a href="tel:17069" target='blank'>
                    <div className="icon-side">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>

                    <div className="txt-side">
                      17069
                    </div>
                  </a>
                </li>


              </ul>
              </div>
            </div>

            <div className=" col-lg-6 col-sm-6  mx-auto">
              <div className="mapee">
                <div className="mape">
                  <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3418.673321634865!2d31.394656061227884!3d31.03535007454603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f79d79031b3cb5%3A0xb2b3ac96eef57fe2!2zSGFtZHkgU2hhbGFieSBCdXRjaGVyIC0g2KzYstin2LHYqSDYrdmF2K_ZiiDYtNmE2KjZiiDZgdix2Lkg2LnYqNivINin2YTYs9mE2KfZhSDYudin2LHZgQ!5e0!3m2!1sar!2seg!4v1724686956095!5m2!1sar!2seg" 
                  width="85%"
                  height="450px"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                  className='mapp'
                ></iframe>
              </div>

              <ul className='locmap'>
                <li className='icon-card'>
                  <a href="https://maps.app.goo.gl/GrN8xin7Pgn7TznE6" target='blank'>
                    <div className="icon-side">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>

                    <div className="txt-side">
                    المنصورة , شارع عبد السلام عارف , بجوار استاد المنصورة .. - المنصورة , شارع قناة السويس خلف مطعم البغل 
                    </div>
                  </a>

                  <a href="https://maps.app.goo.gl/XCnmP8s3NSSYn9V97" target='blank'>
                    <div className="icon-side">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>

                    <div className="txt-side">
                      المنصورة , شارع قناة السويس 
                    </div>
                  </a>
                </li>

                <li className='icon-card'>
                  <a href="tel:17069">
                    <div className="icon-side">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>

                    <div className="txt-side">
                      17069
                    </div>
                  </a>
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MapChoose
