import { useState, useCallback } from "react";
import createComment from "../../context/CommentProvider";
//style
import "../../style/App.css";
import "../../style/form.css";
import "../../style/media/form-media.css";

const Form = () => {
  const [formState, setFormState] = useState({
    name: "",
    comment: "",
  });

  const changeHandler = useCallback((key, value) => {
    setFormState((oldState) => ({ ...oldState, [key]: value }));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    changeHandler(name, value);
  };

  const submitHandler = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const result = await createComment({ formState });
      console.log("Comment created successfully:", result);

      setFormState({
        name: "",
        comment: "",
      });
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  return (
    <>
      <div className="for">
        <div className="container">
                  <div class="overlay"></div>

          <div className="con">
            {/* <p className="hed">opinion</p> */}

            <h1>اضف تعليقك </h1>

            <form onSubmit={submitHandler}>
              <div className="conten row">
                <div className="cont col-lg-12 col-sm-12">
                  <div className="inbut-f">
                    <input
                      type="text"
                      value={formState.name}
                      name="name"
                      onChange={handleChange}
                      placeholder="الاسم الاول "
                      required
                    />
                  </div>
                </div>

                <div className="contt col-lg-12 col-sm-12">
                  <textarea
                    id="message"
                    value={formState.comment}
                    name="comment"
                    placeholder="رسالتك"
                    rows="6"
                    cols="100"
                    required
                    data-aos="fade-up"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <button type="submit" className="send-bt">
                ارسال
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
