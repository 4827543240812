import { createSlice } from "@reduxjs/toolkit";

// Load initial state from localStorage
const cardFromLocalStorage = JSON.parse(localStorage.getItem("FavoriteSlice")) || [];

const FavoriteSlice = createSlice({
    name: "FavoriteSlice",
    initialState: cardFromLocalStorage,  // Use the loaded state as the initial state
    reducers: {
        AddToCard: (state, action) => {
            // Check if the item is already in the state
            const exists = state.some(item => item.id === action.payload.id);

            if (!exists) {
                state.push(action.payload);
                // Update localStorage after state changes
                localStorage.setItem("FavoriteSlice", JSON.stringify(state));
            }
        },
        DeleteCard: (state, action) => {
            // Log the action payload for debugging
            console.log("DeleteCard action payload:", action.payload);

            if (!action.payload || typeof action.payload.id === "undefined") {
                console.error("Invalid action payload:", action.payload);
                return state;
            }

            const newState = state.filter((item) => item.id !== action.payload.id);
            localStorage.setItem("FavoriteSlice", JSON.stringify(newState));
            return newState;
        },
    }
});

export const { AddToCard, DeleteCard } = FavoriteSlice.actions;
export default FavoriteSlice.reducer;
