import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// style
import "../style/App.css";
import "../style/swiper.css";
import "../style/media/swiper-media.css";



// import required modules
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { GetComment } from "../redux/slice/Comment-Slice";



const SwiperComponent = () => {
  const [ setSwiperRef] = useState(null);

  const comment = useSelector((state) => state.comment);

  console.log(comment);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetComment());
  }, [dispatch]);


  return (
    <>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {Array.isArray(comment) && comment.length > 0 ? (
          comment.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="box1" >
                <div className="up">
                  <div className="ri">
                    <h2>{item.name}</h2>
                  </div>
                </div>
                
                <div className="middle"></div>
                
                <div className="domn">
                  <p>{item.comment}</p>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <h1 className="text-center">لا يوجد تعليقات لعرضها </h1>
        )}
      </Swiper>
    </>
  );
};

export default SwiperComponent;
