import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const UrlApi = 'https://menu.hamdyshalaby.com/api'; 

export const GetComment = createAsyncThunk('commentSlice/GetComment', async () => {
    const res = await fetch(`${UrlApi}/all/comments`);
    if (!res.ok) {
        throw new Error('Failed to fetch comments');
    }
    const data = await res.json();
    return data.data.data;
});


const commentSlice = createSlice({
    name: 'commentSlice',
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetComment.fulfilled, (state, action) => {
               return action.payload;
            })

    },
});

export default commentSlice.reducer;