import React from "react";
import { Link } from "react-router-dom";

//style
import "../style/App.css";
import "../style/Footer_choose.css";
import "../style/media/Footer-choose-media.css";

//images
import logo from "../images/Brand_Name.webp";

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import specific icons from the free-solid-svg-icons package
import {
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footerch = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div>
        <section class="icon-foote">
          <div class="container">
            <div className="row web">
              <div className="col-sm-12 col-lg-4 ">
                <div class="mail mx-auto">
                  <h1>تواصل معنا</h1>

                  <div class="icon-container">
                    <a href="tel:01066444004" class="icon-box">
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>

                      <div class="text">
                        <p>01066444004</p>
                      </div>
                    </a>

                    <a href="tel:+20 106 090 9066" class="icon-box">
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>

                      <div class="text">
                        <p>01060909066</p>
                      </div>
                    </a>

                    <a
                      href="https://maps.app.goo.gl/XCnmP8s3NSSYn9V97" 
                      target='blank'
                      class="icon-box"
                    >
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>

                      <div class="text">
                        <p> المنصورة شارع الجيش شيل اوت </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-4 ">
                <div class="logo mx-auto">
                  <div class="icon-container">
                    <a href="https:www.facebook.com" class="icon-box">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>

                    <a href="https:www.twitter.com" class="icon-box">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>

                    <a href="https:www.youtube.com" class="icon-box">
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </div>
                  <div class="img-bk">
                    <img src={logo} loading="lazy" alt="logo" />
                  </div>

                </div>
              </div>

              <div className="col-sm-12 col-lg-4 ">
                <div class="subscribe mx-auto">
                  <h1>
                    لينكات تهمك
                  </h1>

                  <p>
                    <Link to="/home" onClick={scrollToTop}>
                      مطعم 
                    </Link>
                  </p>
                  

                  <p>
                    <Link to="/home/menu" onClick={scrollToTop}>
                      منيو مطعم 
                    </Link>
                  </p>

                  <p>
                    <Link onClick={scrollToTop}>
                      جزارة  
                    </Link>
                  </p>

                  <p>
                    <Link onClick={scrollToTop}>
                      منيو جزارة
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="row mob">
              <div className="col-sm-12 col-lg-4 ">
                <div class="logo mx-auto">
                  <div class="icon-container">
                    <a href="https:www.facebook.com" class="icon-box">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>

                    <a href="https:www.twitter.com" class="icon-box">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>

                    <a href="https:www.youtube.com" class="icon-box">
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </div>
                  <div class="img-bk">
                    <img src={logo} loading="lazy" alt="logo" />
                  </div>

                </div>
              </div>

              <div className="trow">
                <div class="mail mx-auto">
                  <h1>تواصل معنا</h1>

                  <div class="icon-container">
                    <a href="tel:01066444004" class="icon-box">
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>

                      <div class="text">
                        <p>01066444004</p>
                      </div>
                    </a>

                    <a href="tel:+20 106 090 9066" class="icon-box">
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>

                      <div class="text">
                        <p>01060909066</p>
                      </div>
                    </a>

                    <a
                      href="https://maps.app.goo.gl/XCnmP8s3NSSYn9V97" 
                      target='blank'
                      class="icon-box"
                    >
                      <div class="img-bc">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>

                      <div class="text">
                        <p> المنصورة شارع الجيش شيل اوت </p>
                      </div>
                    </a>
                  </div>
                </div>
              
                <div class="subscribe mx-auto">
                  <h1>
                    لينكات تهمك
                  </h1>

                  <p>
                    <Link to="/home" onClick={scrollToTop}>
                      مطعم 
                    </Link>
                  </p>
                  

                  <p>
                    <Link to="/home/menu" onClick={scrollToTop}>
                      منيو مطعم 
                    </Link>
                  </p>

                  <p>
                    <Link onClick={scrollToTop}>
                      جزارة  
                    </Link>
                  </p>

                  <p>
                    <Link onClick={scrollToTop}>
                      منيو جزارة
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div class="container">
            <div class="rhe mx-auto">
              <p>
                copyright 2024 @ ALL right reserved design by
                <a href="https://mrtechv2.netlify.app/">MR Tech</a>
              </p>
            </div>

            <div class="lte mx-auto">
              <p>term of use | privacy policy | support</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footerch;
