import React from 'react'

//style 
import '../../style/App.css';
import '../../style/Testimonials.css';
import '../../style/media/Testimonials-media.css';


//component
import SwiperComponent from '../../component/Swipe.jsx';




const Testimonials = () => {
  return (
    <>
    <div className="testimonials">
      <div className="container">
        <div className="contain">
          <p className='head'>
            اراء العملاء
          </p>
          
          <div className="swip">
            <SwiperComponent/>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Testimonials
