import { Link } from "react-router-dom";

import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// Import required modules
import { FreeMode, Pagination } from 'swiper/modules';

//style
import "../../style/App.css";
import "../../style/Menu_Claas.css";
import "../../style/media/Menu_Claas-media.css";

import Img1 from "../../images/menu/image1.webp";
import Img2 from "../../images/menu/image2.webp";
import Img3 from "../../images/menu/image3.webp";
import Img4 from "../../images/menu/image4.webp";
import Img5 from "../../images/menu/image5.webp";
import Img6 from "../../images/menu/image6.webp";
import Img7 from "../../images/menu/image7.webp";
import Img8 from "../../images/menu/image8.webp";
import Img9 from "../../images/menu/image9.webp";
import Img10 from "../../images/menu/image10.webp";
import Img11 from "../../images/menu/image11.webp";
import Img12 from "../../images/menu/image12.webp";
import Img13 from "../../images/menu/image13.webp";
import Img14 from "../../images/menu/image14.webp";
// import Img14 from "../../images/menu/image1.png";

const products = [
  {
    name: "المشويات",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img6,
    url: "/home/menu1",
  },
  {
    name: "الدجاج المشوي",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img7,
    url: "/home/menu2",
  },{
    name: "الاطباق الرئيسية",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img8,
    url: "/home/menu3",
  },
  {
    name: "المندي",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img9,
    url: "/home/menu4",
  },
  {
    name: "ستيك",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img10,
    url: "/home/menu5",
  },
  {
    name: "الجريل",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img11,
    url: "/home/menu6",
  },
  {
    name: "الشوربة",
    dec: "طواجن حمدي شلبي ، المشاوي ،الدجاج المشوي ، المندي ، ستيك ، الجريل",
    image: Img1,
    url: "/home/menu7",
  },
  {
    name: "السلطات ",
    dec: "السلطات ، الشوربه ، الأصناف الجانبيه ، الأرز و الباستا",
    image: Img2,
    url: "/home/menu8",
  },
  {
    name: "الاطباق الجانبية",
    dec: "السندوتشات البلدي و السوري و فرنساوي",
    image: Img3,
    url: "/home/menu9",
  },
  {
    name: "الارز والباستا",
    dec: "مهلبيه ، كريم بروليه ، كنافه بالكريمه و القشطه ، حلو حمدي شلبي ، جيلي فواكهة",
    image: Img4,
    url: "/home/menu10",
  },
  {
    name: "طواجن",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img5,
    url: "/home/menu11",
  },
  {
    name: "السندوتشات",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img12,
    url: "/home/menu12",
  },
  {
    name: "الحلو",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img13,
    url: "/home/menu13",
  },
  {
    name: "المشروبات",
    dec: "قسم المشروبات البارده و الساخنه و العصائر الفريش",
    image: Img14,
    url: "/home/menu14",
  },
];

const Menu_Class = () => {
  return (
    <>
      <div className="menuClass">
        <div className="overlay"></div>

        <div className="container">
          <div className="heede">
            <h1>
              اصناف المنيو
            </h1>
          </div>

          <div className="boxn">  
            <div className="row conter">
              {products.map((item) => (
                <Link to={item.url} className="col-lg-2 col-sm-12 mx-auto boxff">
                  <div className="imgee">
                    <img src={item.image} alt="menu" loading="lazy" />
                  </div>

                  <div className="conteeen">
                    <div className="wide">
                      <h2>{item.name}</h2> 
                    </div>
                    
                    <div className="wide">
                      <Link to={item.url}>
                        <button>اضغط للمزيد</button>
                      </Link>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="boxm">
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2.5,
                  spaceBetween: 10,
                },
                425: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              modules={[FreeMode, Pagination]}
              className="contere"
            >
              {products.map((item) => (
                <SwiperSlide key={item.id}>
                  <Link to={item.url} className="boxffe">
                    <div className="imgeee">
                      <img src={item.image} alt={item.name} loading="lazy" />
                    </div>

                    <div className="conteeeen">
                      <div className="wide">
                        <h2>{item.name}</h2> 
                      </div>
                      
                      <div className="wide">
                        <Link to={item.url}>
                          <button>اضغط للمزيد</button>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu_Class;
