import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetMenu10 } from "../../../redux/slice/Product-Slice"




import "../../../style/App.css";
import "../../../style/Menu_Claas.css";
import "../../../style/media/Menu_Claas-media.css";
import { AddToCard } from "../../../redux/slice/FvoriteCard-Slice";
import toast, { Toaster } from "react-hot-toast";


const UrlApi = "https://menu.hamdyshalaby.com/";

export default function InsertMenu10() {

  const product = useSelector((state) => state.products)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetMenu10());
    window.scrollTo(0, 0);
  }, [dispatch])


  const ActionClick = (item) => {
    dispatch(AddToCard(item));
    toast.success('تم الاضافه بنجاح');
  }

  return (
    <>
      <div className="menlist">
        <div className="overlay"></div>

        <div className="container">
          <div className="heede">
            <h1>الارز والباستا</h1>
            {/* 
        <p className="descre mx-auto ">
            طواجن حمدي شلبي، المشاوي، الدجاج المشوي، المندي، ستيك، الجريل
        </p> */}
          </div>

          <div className="row">
            {product.length > 0 && (
              <div className="ru col-lg-6 col-sm-12 ">
                <img src={`${UrlApi}${product[0].image}`} alt="menudish" loading="lazy" />
              </div>
            )}


            <div className="lu col-lg-6 col-sm-12">
              <ul>
                {product.map((item) => (
                  <li key={item.id} className="meli">
                    <div className="title">
                      <p>{item.name}</p>

                      <p>
                        {item.coupon_price !== null ? (
                          <>
                            <s>{Math.round(item.price)} ج</s> {Math.round(item.final_price)} ج
                          </>
                        ) : (
                          `${Math.round(item.price)} ج`
                        )}
                      </p>

                      <button
                        className="btnmen"
                        onClick={() => ActionClick(item)}
                      >
                        اضافه للمفضلة
                      </button>
                    </div>

                    <div className="descrr">
                      <p>{item.desc}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  )
}
