import { Link } from "react-router-dom";

//style
import "../style/App.css";
import "../style/NavBar.css";
import "../style/media/NavBar-media.css";

//images
import brand from "../images/Brand_Name.webp";

//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faCartShopping,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const links = [
  { name: "الصفحه الرئيسية", rout: "/home" },
  { name: "قائمة الطعام", rout: "menu" },
  { name: "عروض خاصه", rout: "offer" },
  { name: "من نحن", rout: "aboutme" },
  { name: " المفضلات", rout: "favorite" },
  { name: "تواصل معنا", rout: "https://web.whatsapp.com/" },
];

const NavBar = () => {
  return (
    <>
      <nav class="navBar">
        <div clss="contain ">
          <div className="navbar-upper ">
            <a href="tel:02017069">
              <button className="btn" >اتصل : 17069</button>
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon">
                <i className="Icon-nav-Custom">
                  <FontAwesomeIcon icon={faBars} />
                </i>
              </span>
            </button>

            <div
              class="offcanvas offcanvas-end bg-dark w-75"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>

              <div className="navbare-upper ">
                <a href="tel:02017069" target="blanc">
                  <button>
                    التوصيل : 17069
                  </button>
                </a>

                <a href="https://wa.me/201066444004" target="blanc">
                  <button>
                    التواصل 
                  </button>
                </a>
              </div>

              <ul className="navBar-nave">
                {links.map((link) => (
                  <li
                    className="nav-item"
                    key={link.id}
                    data-bs-dismiss="offcanvas"
                  >
                    <Link to={link.rout} className="nav-link">
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="navbar-nave-soc">
                <a
                  href="https://www.facebook.com/hamdy.shalaby.restaurant?mibextid=LQQJ4d"
                  target="blanc"
                  className="nav-link-soc"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>

                <a
                  href="https://www.instagram.com/hamdyshalaby_restaurant?igsh=YXJkdGc0OWc4YTc4"
                  target="blanc"
                  className="nav-link-soc"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>

                <a href="https://wa.me/201066444004" 
                target="blanc"
                className="nav-link-soc">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </ul>
            </div>

            <img src={brand} alt="Brand Logo" loading="lazy" />

            <div className="deliver">
              {/* <FontAwesomeIcon icon={faCartShopping} /> */}

              <a href="https://wa.me/201066444004">
                <button className="btn">
                  التواصل
                </button>
              </a>
            </div>
          </div>

          <div className="navbar-nav-con">
            <ul className="navBar-nav">
              {links.map((link, index) => (
                <li className="nav-item" key={index}>
                  <Link to={link.rout} className="nav-link">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="navbar-nave-soc">
              <a
                href="https://www.facebook.com/hamdy.shalaby.restaurant?mibextid=LQQJ4d"
                target="blanc"
                className="nav-link-soc"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>

              <a
                href="https://www.instagram.com/hamdyshalaby_restaurant?igsh=YXJkdGc0OWc4YTc4"
                target="blanc"
                className="nav-link-soc"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>

              <a href="https://wa.me/201066444004" 
                target="blanc"
                className="nav-link-soc">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
