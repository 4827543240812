import React from "react";

//style
import "../style/App.css";
import "../style/Footer.css";
import "../style/media/Footer-media.css";

//images
import logo from "../images/Brand_Name.webp";

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import specific icons from the free-solid-svg-icons package
import {
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTiktok,
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <div>
        <div className="mobd">
          <section class="icon-footer ">
            <div class="container">
              <div className="row">
                <div className="col-sm-12 col-lg-4 ">
                  <div class="mail mx-auto">
                    <h1>تواصل معنا</h1>

                    <div class="icon-container">
                      <a href="https://https://www.tiktok.com/@hamdyshalaby_restaurant" target="blanc" class="icon-box">
                        <div class="img-bc">
                          <FontAwesomeIcon icon={faTiktok} />
                        </div>

                        <div class="text">
                          <p>hamdyshalaby_restaurant</p>
                        </div>
                      </a>

                      <a href="https://wa.me/201066444004"target="blanc" class="icon-box">
                        <div class="img-bc">
                          <FontAwesomeIcon icon={faPhone} />
                        </div>

                        <div class="text">
                          <p>201066444004</p>
                        </div>
                      </a>

                      <a
                      
                        href="https://maps.app.goo.gl/5Btahmm5JuDydPzh9"
                        target="blanc"
                        class="icon-box"
                      >
                        <div class="img-bc">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </div>

                        <div class="text">
                          <p>المنصورة شارع الجيش شيل اوت</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-4 ">
                  <div class="logo mx-auto">
                    <div class="icon-container">
                      <a
                        href="https://www.facebook.com/hamdy.shalaby.restaurant?mibextid=LQQJ4d"
                        target="blanc"
                        className="nav-link-soc"
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>

                      <a
                        href="https://www.instagram.com/hamdyshalaby_restaurant?igsh=YXJkdGc0OWc4YTc4"
                        target="blanc"
                        className="nav-link-soc"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>

                      <a href="https://wa.me/201066444004" 
                      target="blanc"
                      className="nav-link-soc">
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </a>
                    </div>
                    
                    <div class="img-bk">
                      <img src={logo} loading="lazy" alt="logo" />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-4 ">
                  <div class="subscribe mx-auto">
                    <h1>القائمه</h1>

                    <ul className="txx">
                      <li>
                        <a href="">
                          الرئيسية
                        </a>
                      </li>

                      <li>
                        <a href="">
                          قائمة الطعام
                        </a>
                      </li>

                      <li>
                        <a href="">
                          عروض خاصة
                        </a>
                      </li>

                      <li>
                        <a href="">
                          من نحن
                        </a>
                      </li>

                      <li>
                        <a href="">
                          المفضلات
                        </a>
                      </li>

                      <li>
                        <a href="">
                          تواصل معنا
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="mobv">
          <section class="icon-footerr ">
            <div class="container">
              <div class="logoo cool-sm-12 mx-auto">                    
                <div class="img-bk">
                  <img src={logo} loading="lazy" alt="logo" />
                </div>

                <div class="icon-container">
                  <a
                  href="https://www.facebook.com/hamdy.shalaby.restaurant?mibextid=LQQJ4d"
                  target="blanc"
                  className="nav-link-soc"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>

                  <a
                  href="https://www.instagram.com/hamdyshalaby_restaurant?igsh=YXJkdGc0OWc4YTc4"
                  target="blanc"
                  className="nav-link-soc"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>

                  <a href="https://wa.me/201066444004" 
                  target="blanc"
                  className="nav-link-soc">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </div>
              </div>

              <div class="maill col-sm-12 mx-auto">
                <h1>تواصل معنا</h1>

                <div class="icon-container">
                  <a href="https://https://www.tiktok.com/@hamdyshalaby_restaurant" target="blanc" class="icon-box">
                    <div class="img-bc">
                      <FontAwesomeIcon icon={faTiktok} />
                    </div>

                    <div class="text">
                        <p>hamdyshalaby_restaurant</p>
                      </div>
                  </a>

                  <a href="https://wa.me/201066444004"target="blanc" class="icon-box">
                    <div class="img-bc">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>

                    <div class="text">
                      <p>201066444004</p>
                    </div>
                  </a>

                  <a              
                  href="https://maps.app.goo.gl/5Btahmm5JuDydPzh9"
                  target="blanc"
                  class="icon-box"
                  >
                    <div class="img-bc">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>

                    <div class="text">
                      <p>المنصورة شارع الجيش شيل اوت</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer>
          <div class="container">
            <div class="rhe mx-auto">
              <p>
                copyright 2024 @ ALL right reserved design by
                <a href="https://mrtechv2.netlify.app/">MR Tech</a>
              </p>
            </div>

            <div class="lte mx-auto">
              <p>term of use | privacy policy | support</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
