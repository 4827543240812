import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Importing styles
import '../../style/App.css';
import '../../style/map.css';

// Fixing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = () => {
  return (
    <div className='map' >
      <div className="container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3418.5724946858536!2d31.39728862475701!3d31.038158374438353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f79dbe4aaa8555%3A0x5ad28a8c2afee581!2zSGFtZHkgU2hhbGFieSBSZXN0YXVyYW50IHwg2YXYt9i52YUg2K3Zhdiv2Yog2LTZhNio2Yog2YTZhNmF2LTZiNmK2KfYqg!5e0!3m2!1sar!2seg!4v1724443090969!5m2!1sar!2seg"
        width="95%"
        height="450px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
      </div>
    </div>
  );
}

export default Map;
