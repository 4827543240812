import { useDispatch, useSelector } from "react-redux";
import { GetOffer } from "../../redux/slice/Product-Slice";

//style
import "../../style/App.css";
import "../../style/Offer.css";
import "../../style/media/Offer-media.css";
import { useEffect } from "react";

const UrlApi = "https://menu.hamdyshalaby.com/";

const Offer = () => {
  const productOffer = useSelector((state) => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOffer());
  }, [dispatch]);

  console.log(productOffer);
  return (
    <>
      <div className="offer">
        <div className="container">
          <div className="hed">
            <p className="head">عرض</p>

            <h1>عروض خاصة على الطباق</h1>
            {/* 
            <p>
              ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان لوريم إيبسوم
              هو الدمية القياسية في هذه الصناعة.
            </p> */}
          </div>

          <div className="body row my-5">
            {Array.isArray(productOffer) && productOffer.length > 0 ? (
              productOffer.map((item) => (
                <div className="box1">
                  <div className="re">
                    <div className="up">
                      <p>{Math.round(parseFloat(item.coupon_price))}%</p>
                    </div>

                    <div className="down">
                      <p className="hed">{`عرض  ${item.coupon_name}`}</p>

                      <h3>{item.name}</h3>

                      <p>{item.desc}</p>
                      <p>
                        {item.coupon_price != null ? (
                          <>
                            <span>سعر المنتج : </span>
                            <s>{Math.round(item.price)} ج</s>
                            <span> {Math.round(item.final_price)} ج</span>
                          </>
                        ) : (
                          <p>سعر المنتج : {Math.round(item.price)} ج</p>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="le">
                    <img
                      src={`${UrlApi}${item.image}`}
                      alt={item.name}
                      loading="lazy"
                    />
                  </div>
                </div>
              ))
            ) : (
              <h1 className="text-center">لا يوجد منتجات لعرضها </h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Offer;
