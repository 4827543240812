import axios from "axios";

const createComment = async ({ formState }) => {
    try {
        const formData = new FormData();
        for (let key in formState) {
            formData.append(key, formState[key]);
        }
        const response = await axios.post("https://menu.hamdyshalaby.com/api/comment/create", formData);
        if (response.status === 200) {
            return response.data.data
        }
    } catch (e) {
        console.error(e);
        if (e.response) {
            console.error(e.response.data.message);
        } else {
            console.error(e.message);
        }
        throw e;
    }
}
export default createComment;
