//style
import "../../style/App.css";
import "../../style/Choose.css";
import "../../style/media/Choose_media.css";

//images
import butcher    from "../../images/choose//Butcher_View.webp";
import restaurant from "../../images/choose/Restaurant_View.webp";

import cow  from "../../images/icons/cow-head-outline-svgrepo-com.svg";
import menu from "../../images/icons/restaurant-menu-svgrepo-com.svg";
import { Link } from "react-router-dom";

const Choose = () => {
  return (
    <>
      <section id="choose">
        <div className="container">
          <h1>خدماتنا </h1>

          <p>
            مطعم حمدي شلبي هو وجهة مثالية لمحبي اللحوم 
          </p>

          <div className="row">
            <div className="card-cont">
              <div className="col-lg-6 ">
                <Link to={"/home"} className="carde">
                    <img src={restaurant} alt="place" className="loc" loading="lazy"/>
                    
                    <img src={menu}       alt="icon"  className="cow" loading="lazy"/>

                    <div className="body">
                      <p>مطعم</p>
                    </div>
                </Link>
              </div>

              <div className="col-lg-6 ">
                <a href="https://butcher1.hamdyshalaby.com/" className="carde ">
                  <img src={butcher} alt="place" className="loc" loading="lazy"/>

                  <img src={cow}     alt="icon" className="cow" loading="lazy"/>

                  <div className="body"></div>
                    <div className="body">
                
                    <p>الجزارة</p>

                  </div>            
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Choose;
