import React from "react";

//style
import "../../style/App.css";
import "../../style/Header.css";
import "../../style/media/Header-media.css";

//images
import head_img from "../../images/win.webp";

////
// import About from "./About";
import Testimonials from "./Testimonials";
import Offer from "./Offer";
import Menu from "./Menu";
import Form from "./Form";
import { Link } from "react-router-dom";
import GallaryResturant from "./GallaryResturant";
import Menu_Class from "./Menu_Claas";
import Map from "./Map"
import MenuList from "./MenuList"


const Header = () => {
  return (
    <>
      <header className="hedete">
        <div className="content">
          <div className="left">
            <h1 className="text-dark">أهلا بكم في مطعم حمدي شلبي</h1>

            {/* <p>
              ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان لوريم إيبسوم
              هو الدمية القياسية في هذه الصناعة.
            </p> */}
            <Link to={"/home/menu"}>
              <button>عرض القائمة</button>
            </Link>
          </div>

          <div className="right">
            <img src={head_img} alt="header" loading="lazy" />
          </div>
        </div>
      </header>

      <header className="back-mobile">
        <div className="overlay"></div>
        <div className="content">
          <div className="left">
            <h1 className="text-dark">أهلا بكم في مطعم حمدي شلبي</h1>

            {/* <p>
              ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان لوريم إيبسوم
              هو الدمية القياسية في هذه الصناعة.
            </p> */}
            <Link to={"/home/menu"}>
              <button>عرض القائمة</button>
            </Link>
          </div>

          <div className="right">
            <img src={head_img} alt="header" loading="lazy" />
          </div>
        </div>
      </header>

      {/* <About /> */}
      {/* <Offer /> */}
      <Menu />
      {/* <Menu_Class/> */}
      <Testimonials />
      <Form />
      <GallaryResturant />
      <Map/>
      {/* <MenuList/> */}
    </>
  );
};

export default Header;
