import { configureStore } from "@reduxjs/toolkit";
import ProductSlice from "./slice/Product-Slice";
import CommentSlice from "./slice/Comment-Slice";
import FavoriteSlice from "./slice/FvoriteCard-Slice";
import AboutmeSlice from "./slice/Aboutme-Slice";

export const store = configureStore({
    reducer: {
        products: ProductSlice,
        comment: CommentSlice,
        card: FavoriteSlice,
        about: AboutmeSlice
    }
});