import React from 'react'
import { Link } from "react-router-dom";


//style
import '../../style/App.css';
import '../../style/Choheader.css';
import '../../style/media/Choheader-media.css';

import imgBu  from '../../images/choose/Butcher_View.webp';
import imgBu2 from '../../images/choose/ButcherView2.webp';

import imgRu  from '../../images/choose/RestaurantView.webp';
import imgRu2 from '../../images/choose/RestaurantView1.webp';


const ChooseHeader = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className='choheader'>
        <div className="container">
          <div className="row">
            <div className="lec col-lg-6 col-sm-4">
              <div className="chosgal">
                <div className="img-box pb-5">
                  <img src={imgRu} alt="resturan" loading="lazy" />
                </div>

                <div className="img-box pt-5">
                  <img src={imgRu2} alt="resturan" loading="lazy" />
                </div>
                
                <div className="img-box pb-5">
                  <img src={imgBu} alt="Butcher" loading="lazy"/>
                </div>

                <div className="img-box pt-5">
                  <img src={imgBu2} alt="Butcher" loading="lazy"/>
                </div>
              </div>
            </div>

          <div className="rec col-lg-6 col-sm-4">
                <h1>
                  مطعم حمدي شلبي واجهة مثالية
                  لمحبي كل انواع اللحوم
                </h1>

                <Link to="/home" onClick={scrollToTop}>
                  <button className='btnc'>
                    تذوق الان
                  </button>
                </Link>
              </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ChooseHeader
