import { useEffect } from "react";

//style
import "../../style/App.css";
import "../../style/About.css";
import "../../style/media/About-media.css";

//images
import about from "../../images/Front-VIew.webp";

//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faClock,
  faFilePen,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { GetAbout } from "../../redux/slice/Aboutme-Slice";

const About = () => {
  const item = useSelector((state) => state.about);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAbout());
    window.scrollTo(0, 0);
  }, [dispatch]);


  return (
    <>
      <div className="about">
        <div className="container">
          <div className="lefe">
            <img src={about} alt="about" loading="lazy" />
          </div>

          <div className="box-container ">
            <div className="box">
              <a
                href="https://maps.app.goo.gl/bVwC5jpAx4pYTUm78"
                className="left"
              >
                <FontAwesomeIcon icon={faLocationDot} className="icon-about" />
              </a>

              <div className="rghte">
                <h2>موقعنا</h2>

                <p>مصر الطيران</p>
              </div>
            </div>

            <div className="box">
              <div className="left">
                <FontAwesomeIcon icon={faClock} className="icon-about" />
              </div>

              <div className="rghte">
                <h2>ساعات العمل</h2>

                <p>كل الايام</p>
              </div>
            </div>

            <div className="box">
              <div className="left">
                <FontAwesomeIcon icon={faFilePen} className="icon-about" />
              </div>

              <div className="rghte">
                <h2>الحجز</h2>

                <p>mailmail@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="contain">
            <div className="box-cot">
              <div className="rght">
                <div className="up">
                  <h3>القصة</h3>

                  <p className="his">
                    ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان لوريم
                    إيبسوم هو الدمية القياسية في هذه الصناعة.
                  </p>
                </div>

                <div className="mid">
                  <div className="r">
                    <h3>1996</h3>

                    <p className="his">
                      ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان
                      لوريم إيبسوم هو الدمية القياسية في هذه الصناعة.
                    </p>
                  </div>

                  <div className="l">
                    <h3>2021</h3>

                    <p className="his">
                      ببساطة، نص وهمي للطباعة وصناعة أدوات الطباعة. لقد كان
                      لوريم إيبسوم هو الدمية القياسية في هذه الصناعة.
                    </p>
                  </div>
                </div>

                <div className="foot">
                  <h3>حمدي شلبي</h3>
                </div>
              </div>

              <div class="middil"></div>

              <div className="lef">
                <img src={about} alt="about" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
