import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



const UrlApi = "https://menu.hamdyshalaby.com/api"


export const GetMenu1 = createAsyncThunk("productsSlice/GetMenu1", async () => {
  const res = await fetch(`${UrlApi}/menus/2`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu2 = createAsyncThunk("productsSlice/GetMenu2", async () => {
  const res = await fetch(`${UrlApi}/menus/3`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu3 = createAsyncThunk("productsSlice/GetMenu3", async () => {
  const res = await fetch(`${UrlApi}/menus/4`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu4 = createAsyncThunk("productsSlice/GetMenu4", async () => {
  const res = await fetch(`${UrlApi}/menus/5`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu5 = createAsyncThunk("productsSlice/GetMenu5", async () => {
  const res = await fetch(`${UrlApi}/menus/17`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu6 = createAsyncThunk("productsSlice/GetMenu6", async () => {
  const res = await fetch(`${UrlApi}/menus/6`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu7 = createAsyncThunk("productsSlice/GetMenu7", async () => {
  const res = await fetch(`${UrlApi}/menus/7`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu8 = createAsyncThunk("productsSlice/GetMenu8", async () => {
  const res = await fetch(`${UrlApi}/menus/8`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu9 = createAsyncThunk("productsSlice/GetMenu9", async () => {
  const res = await fetch(`${UrlApi}/menus/9`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu10 = createAsyncThunk("productsSlice/GetMenu10", async () => {
  const res = await fetch(`${UrlApi}/menus/10`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu11 = createAsyncThunk("productsSlice/GetMenu11", async () => {
  const res = await fetch(`${UrlApi}/menus/11`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu12 = createAsyncThunk("productsSlice/GetMenu12", async () => {
  const res = await fetch(`${UrlApi}/menus/12`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu13 = createAsyncThunk("productsSlice/GetMenu13", async () => {
  const res = await fetch(`${UrlApi}/menus/13`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu14 = createAsyncThunk("productsSlice/GetMenu14", async () => {
  const res = await fetch(`${UrlApi}/menus/14`);
  const data = await res.json();
  return data.data.menus;

})

export const GetProducts = createAsyncThunk("productsSlice/GetProducts", async () => {
  const res = await fetch(`${UrlApi}/menus/15`);
  const data = await res.json();
  return data.data.menus;

})

export const GetOffer = createAsyncThunk("productsSlice/GetOffer", async () => {
  const res = await fetch(`${UrlApi}/menus/spcail/16`);
  const data = await res.json();
  return data.data.menuSpecials;
})

const productsSlice = createSlice({
  initialState: [],
  name: "productsSlice",
  extraReducers: (builder) => {
    builder
      .addCase(GetProducts.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu1.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu2.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu3.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu4.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu5.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu6.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu7.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu8.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu9.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu10.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu11.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu12.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu13.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu14.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetOffer.fulfilled, (state, action) => {
        return action.payload
      })
  }
})


export default productsSlice.reducer;