
//style
import '../../style/App.css';
import '../../style/HelloPage.css';
import '../../style/media/HelloPage-media.css';


//images
import brand from '../../images/Brand_Name_hero.webp';

import Choose from './Choose';
import Footerch from '../../component/FooterChoose';
import ChooseHeader from './ChooseHeader';
import BannerChoose from './BannerChoose';
import MapChoose from './MapChoose';




export default function HelloPgae() {
    return (
        <>
            <header className='headertt'>
                <div className="container mx-auto">
                    <img src={brand} alt="Brand Logo" loading="lazy" className='w-50'/>
                </div>
            </header>

            <ChooseHeader/>
            <BannerChoose/>
            <Choose />
            <MapChoose/>
            <Footerch />

           
        </>
    )
}
