import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteCard } from '../redux/slice/FvoriteCard-Slice';

import "../style/App.css";
import "../style/Menu.css";
import "../style/media/Menu-media.css";

const UrlApi = "https://menu.hamdyshalaby.com/";

export default function Favoritepage() {

    const favoriteCard = useSelector((state) => state.card);

    const dispatch = useDispatch()

    return (
        <>
            <div className="menu ">
                <div className="container">
                    <div className="content ">


                        <h1>المفضلات</h1>


                        <div className="row ">
                            {Array.isArray(favoriteCard) && favoriteCard.length > 0 ? (
                                favoriteCard.map((item) => (
                                    <div className="col-lg-3 col-sm-12 boxf mx-auto" key={item.id}>
                                        <div className="imge">
                                            <img src={`${UrlApi}${item.image}`} alt={item.name} />
                                        </div>

                                        <div className="conteen">
                                            <div className="heading">
                                                <h3>{item.name}</h3>

                                                {item.coupon_price != null ? (
                                                    <>
                                                        <span>سعر المنتج : </span>
                                                        <s>{Math.round(item.price)} ج</s>
                                                        <span>  {Math.round(item.final_price)} ج</span>
                                                    </>
                                                ) : (
                                                    <p>سعر المنتج : {Math.round(item.price)} ج</p>
                                                )}
                                            </div>

                                            <div className="middle"></div>

                                            <div className="descr">
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                        <button className='btn px-3 bg-danger text-white ' onClick={() => dispatch(DeleteCard(item))}>احذف المنتج</button>
                                    </div>
                                ))
                            ) : (
                                <h2> لا يوجد منتجات لعرضها </h2>
                            )}
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}