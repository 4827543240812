import { Outlet } from "react-router-dom";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";


export default function Root() {
    return (
        <>
            <NavBar />

            <Outlet />

            <Footer />
        </>
    )
}
