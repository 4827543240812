import React from 'react'

//style
import '../../style/App.css';
import '../../style/BannCh.css';
import '../../style/media/BannCh-media.css';


import logo from '../../images/choose/LogoRetaurant.webp'
const BannerChoose = () => {
  return (
    <>
      <div className="BannChe">
        <div className="container">
          <img src={logo} alt="" />
        </div>
      </div>
    </>
  )
}

export default BannerChoose
