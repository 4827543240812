import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



export const GetAbout = createAsyncThunk('AboutMeSlice/GetAbout', async () => {
    const res = await fetch(`https://menu.hamdyshalaby.com/api/about`);
    if (!res.ok) {
        throw new Error('Failed to fetch comments');
    }
    const data = await res.json();
    return data.data;
});


const AboutMeSlice = createSlice({
    name: 'Aboutme',
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAbout.fulfilled, (state, action) => {
                return action.payload;
            })

    },
});

export default AboutMeSlice.reducer;