
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

//style
import '../../style/App.css';
import '../../style/GallaryResturant.css';
import '../../style/media/GallaryResturant-media.css';

//images
import img1 from '../../images/gallarey/Gallary(7).webp';
import img2 from '../../images/gallarey/Gallary(9).webp';
import img3 from '../../images/gallarey/Gallary(3).webp';
import img4 from '../../images/gallarey/Gallary(4).webp';
import img5 from '../../images/gallarey/Gallary(5).webp';





// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination,Navigation} from 'swiper/modules';

export default function GallaryResturant() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

    return (
        <>
            <div className='gall'>
                <div className='herd'>
                    <h1>
                        معرض الصور 
                    </h1> 
                </div>

                <Swiper
                    navigation={true}
                    pagination={{ clickable: true }}
                    modules={[Pagination, Navigation]}
                    className="gallery-swip">
                    

                    <SwiperSlide>
                    <div className="col-lg-12 gal-im mt-2">
                        <a href={img1} data-lightbox="gallery" data-title={img3}>
                            <img src={img1} alt="Gallery Image 3"  loading='lazy' />
                        </a>
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12 gal-im mt-2">
                        <a href={img2} data-lightbox="gallery" data-title={img1}>
                            <img src={img2} alt="Gallery Image 1" loading='lazy' />
                        </a>
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12 gal-im mt-2">
                        <a href={img3} data-lightbox="gallery" data-title={img2}>
                            <img src={img3} alt="Gallery Image 2"  loading='lazy' />
                        </a>
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="col-lg-12 gal-im mt-2">
                        <a href={img4} data-lightbox="gallery" data-title={img4}>
                            <img src={img4} alt="Gallery Image 4"  loading='lazy' />
                        </a>
                    </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="col-lg-12 gal-im mt-4">
                            <a href={img5} data-lightbox="gallery" data-title={img5}>
                                <img src={img5} alt="Gallery Image 5"  loading='lazy' />
                            </a>
                        </div>
                    </SwiperSlide>
                </Swiper>
                
                <div>   
                </div>
            </div>
        </>
    );
}
